import Head from "next/head";
import Layout from "@/components/layout";
import { landingPagesQuery } from "@/lib/queries";
import { getClient, overlayDrafts } from "@/lib/sanity.server";
import SegmentGenerator from "@/components/segment-generator";
import { Carousel } from "@/components/carousel";
import BannerMessage from "@/components/banner-message";
import Link from "next/link";

export default function Index({ pageContent, preview }) {
  const heroSegments = pageContent.segments.filter((segment) =>
    segment.layout.includes("hero")
  );
  const otherSegments = pageContent.segments.filter(
    (segment) => !segment.layout.includes("hero")
  );

  return (
    <>
      <Layout preview={preview}>
        <Head>
          <title>{pageContent.title}</title>
          <meta name="description" content={pageContent.metaDescription} />
          <meta property="og:title" content={pageContent.title} />
          <meta
            property="og:description"
            content={pageContent.metaDescription}
          />
          <meta
            property="og:url"
            content={`${process.env.NEXT_PUBLIC_HOST}/`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="article:modified_time"
            content={pageContent._updatedAt}
          ></meta>
          <meta property="og:site_name" content="JustShip" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content={pageContent.title}></meta>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="10 minutes" />
        </Head>
        {process.env.NEXT_PUBLIC_SHOW_BANNER_MESSAGE === "1" && (
          <BannerMessage>
            <div className="hidden sm:block">
              <Link href="/shipping-chinese-new-year-goodies-internationally-guide">
                <a className="text-inherit">
                  {
                    "🐲 Send joy this CNY! Learn how to send CNY goodies overseas with our 2024 guide! Cheers to a prosperous New Year! 🍊🍊"
                  }
                </a>
              </Link>
            </div>
            <div className="sm:hidden">
              <Link href="/shipping-chinese-new-year-goodies-internationally-guide">
                <a className="text-inherit">
                  {
                    "🐲 Send joy this CNY! Learn how to send CNY goodies overseas with our 2024 guide!\nCheers to a prosperous New Year! 🍊🍊"
                  }
                </a>
              </Link>
            </div>
          </BannerMessage>
        )}
        <div className="bg-background-dark-gray">
          <Carousel segments={heroSegments} />
          {otherSegments.map((segment) => (
            <SegmentGenerator key={segment._id} segment={segment} />
          ))}
        </div>
      </Layout>
    </>
  );
}

export async function getStaticProps({ preview = false }) {
  const pageContent = overlayDrafts(
    await getClient(preview).fetch(landingPagesQuery, {
      slug: "/",
    })
  )[0];
  return {
    props: { pageContent, preview },
  };
}
