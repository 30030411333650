import classNames from "classnames";

export default function BannerMessage({ children }) {
  return (
    <div className="bg-pink p-3.5 opacity-90">
      <div
        className={classNames(
          "text-center text-base mx-auto whitespace-pre-line",
          "text-white font-semibold hover:underline"
        )}
      >
        {children}
      </div>
    </div>
  );
}
